/* tslint:disable */
/* eslint-disable */
/**
 * Groenhart API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.47
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProductCardApiModelDeliveryTime } from './ProductCardApiModelDeliveryTime';
import {
    ProductCardApiModelDeliveryTimeFromJSON,
    ProductCardApiModelDeliveryTimeFromJSONTyped,
    ProductCardApiModelDeliveryTimeToJSON,
} from './ProductCardApiModelDeliveryTime';
import type { ProductStickerApiModel } from './ProductStickerApiModel';
import {
    ProductStickerApiModelFromJSON,
    ProductStickerApiModelFromJSONTyped,
    ProductStickerApiModelToJSON,
} from './ProductStickerApiModel';

/**
 * 
 * @export
 * @interface ProductCardApiModel
 */
export interface ProductCardApiModel {
    /**
     * 
     * @type {Array<ProductStickerApiModel>}
     * @memberof ProductCardApiModel
     */
    stickers?: Array<ProductStickerApiModel> | null;
    /**
     * 
     * @type {string}
     * @memberof ProductCardApiModel
     */
    sku?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductCardApiModel
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductCardApiModel
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductCardApiModel
     */
    mainImage?: string | null;
    /**
     * 
     * @type {ProductCardApiModelDeliveryTime}
     * @memberof ProductCardApiModel
     */
    deliveryTime?: ProductCardApiModelDeliveryTime;
    /**
     * 
     * @type {string}
     * @memberof ProductCardApiModel
     */
    deliveryText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductCardApiModel
     */
    brandName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductCardApiModel
     */
    mainCategory?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductCardApiModel
     */
    disabledForDebtorNumber?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof ProductCardApiModel
     */
    unitQuantity?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductCardApiModel
     */
    unit?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductCardApiModel
     */
    priceExclVat?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductCardApiModel
     */
    listPriceExclVat?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductCardApiModel
     */
    priceInclVat?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductCardApiModel
     */
    listPriceInclVat?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductCardApiModel
     */
    requiredSaleQuantity?: number | null;
}

/**
 * Check if a given object implements the ProductCardApiModel interface.
 */
export function instanceOfProductCardApiModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProductCardApiModelFromJSON(json: any): ProductCardApiModel {
    return ProductCardApiModelFromJSONTyped(json, false);
}

export function ProductCardApiModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductCardApiModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stickers': !exists(json, 'stickers') ? undefined : (json['stickers'] === null ? null : (json['stickers'] as Array<any>).map(ProductStickerApiModelFromJSON)),
        'sku': !exists(json, 'sku') ? undefined : json['sku'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'mainImage': !exists(json, 'mainImage') ? undefined : json['mainImage'],
        'deliveryTime': !exists(json, 'deliveryTime') ? undefined : ProductCardApiModelDeliveryTimeFromJSON(json['deliveryTime']),
        'deliveryText': !exists(json, 'deliveryText') ? undefined : json['deliveryText'],
        'brandName': !exists(json, 'brandName') ? undefined : json['brandName'],
        'mainCategory': !exists(json, 'mainCategory') ? undefined : json['mainCategory'],
        'disabledForDebtorNumber': !exists(json, 'disabledForDebtorNumber') ? undefined : json['disabledForDebtorNumber'],
        'unitQuantity': !exists(json, 'unitQuantity') ? undefined : json['unitQuantity'],
        'unit': !exists(json, 'unit') ? undefined : json['unit'],
        'priceExclVat': !exists(json, 'priceExclVat') ? undefined : json['priceExclVat'],
        'listPriceExclVat': !exists(json, 'listPriceExclVat') ? undefined : json['listPriceExclVat'],
        'priceInclVat': !exists(json, 'priceInclVat') ? undefined : json['priceInclVat'],
        'listPriceInclVat': !exists(json, 'listPriceInclVat') ? undefined : json['listPriceInclVat'],
        'requiredSaleQuantity': !exists(json, 'requiredSaleQuantity') ? undefined : json['requiredSaleQuantity'],
    };
}

export function ProductCardApiModelToJSON(value?: ProductCardApiModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'stickers': value.stickers === undefined ? undefined : (value.stickers === null ? null : (value.stickers as Array<any>).map(ProductStickerApiModelToJSON)),
        'sku': value.sku,
        'name': value.name,
        'type': value.type,
        'mainImage': value.mainImage,
        'deliveryTime': ProductCardApiModelDeliveryTimeToJSON(value.deliveryTime),
        'deliveryText': value.deliveryText,
        'brandName': value.brandName,
        'mainCategory': value.mainCategory,
        'disabledForDebtorNumber': value.disabledForDebtorNumber,
        'unitQuantity': value.unitQuantity,
        'unit': value.unit,
        'priceExclVat': value.priceExclVat,
        'listPriceExclVat': value.listPriceExclVat,
        'priceInclVat': value.priceInclVat,
        'listPriceInclVat': value.listPriceInclVat,
        'requiredSaleQuantity': value.requiredSaleQuantity,
    };
}

